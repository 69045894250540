import { FC, ReactNode, useState, useContext, useEffect } from 'react';
import { NavLink as RouterLink, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  Button,
  Tooltip,
  Badge,
  Collapse,
  ListItem,
  TooltipProps,
  tooltipClasses,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { checkUrl } from 'src/utils/checkUrl';
import { asyncLocalStorage } from 'src/utils/asyncLocalStorage';
import useAuth from 'src/hooks/useAuth';
import { getFirstDayOfMonth } from 'src/utils/date';
import { ImpactContext } from 'src/contexts/ImpactContext';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  badgeTooltip?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { dateRange, isSuperAdmin, showImpactInfinity } =
    useContext(ImpactContext);
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { t }: { t: any } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);
  const { user } = useAuth();
  const user1 = JSON.parse(localStorage.getItem('user'));
  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ active: menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              {badge === '' ? (
                <Badge color="primary" variant="dot" />
              ) : (
                <Badge badgeContent={badge} />
              )}
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }
  const generateLink = (link: string) => {
    let accountId;
    let advertiserId;
    if (
      searchParams.get('accountId') &&
      (searchParams.get('accountId') !== null ||
        searchParams.get('accountId') !== undefined)
    ) {
      accountId = searchParams.get('accountId') as string;
      advertiserId = searchParams.get('advertiserId') as string;
    } else {
      asyncLocalStorage.getItem('user').then((user) => {
        asyncLocalStorage.getItem('selectedAdvertiser').then((adv) => {
          if (
            user &&
            adv &&
            checkUrl('ß') === false &&
            checkUrl('company-account') === false
          ) {
            setSearchParams({
              accountId:
                JSON.parse(user).accountId ||
                (isSuperAdmin === 'true' &&
                  JSON.parse(adv)?.account?.accountId),
              advertiserId: JSON.parse(adv).advertiserId
            });
            accountId =
              JSON.parse(user).accountId ||
              (isSuperAdmin === 'true' && JSON.parse(adv)?.account?.accountId);

            advertiserId = JSON.parse(adv).advertiserId;
          } else if (user) {
            setSearchParams({
              accountId:
                JSON.parse(user).accountId ||
                (isSuperAdmin === 'true' && JSON.parse(adv)?.account?.accountId)
            });
            accountId = JSON.parse(user).accountId;
          }
        });
      });
    }
    // generate `infinity` param only if `showImpactInfinity` is true
    const getInfinityParam = () =>
      showImpactInfinity
        ? `&infinity=${searchParams.get('infinity') || 'false' }`
        : '';

    if (link.includes('insights/media') || link.includes('insights/site')) {
      return searchParams.get('startDate') !== null
        ? `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}&startDate=${searchParams.get(
            'startDate'
          )}`
        : searchParams.get('customDateRangeId') !== null
        ? `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}&customDateRangeId=${searchParams.get(
            'customDateRangeId'
          )}`
        : `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}&startDate=${getFirstDayOfMonth(
            dateRange
          )}`;
    } else if (link.includes('home')) {
      return `${link}?accountId=${accountId}${getInfinityParam()}`;
    } else if (link.includes('features')) {
      return `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}`;
    } else if (link.includes('insights/setup')) {
      return `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}`;
    } else if (link.includes('company-account')) {
      return `${link}?accountId=${accountId}${getInfinityParam()}`;
    } else if (link.includes('private-consortium')) {
      return `${link}?accountId=${accountId}&advertiserId=${advertiserId}${getInfinityParam()}`;
    } else if (link.includes('admin')) {
      return `${link}?adminMode=${true}`;
    }
  };
  return (
    <ListItem component="div" key={name} {...rest}>
      <Button
        disableRipple
        component={RouterLink}
        onClick={closeSidebar}
        //to={`${link}?${searchParams}`}
        to={generateLink(link)}
        startIcon={Icon && <Icon />}
      >
        {t(name)}
        {badgeTooltip ? (
          <TooltipWrapper title={badgeTooltip} arrow placement="right">
            {badge === '' ? (
              <Badge color="primary" variant="dot" />
            ) : (
              <Badge badgeContent={badge} />
            )}
          </TooltipWrapper>
        ) : badge === '' ? (
          <Badge color="primary" variant="dot" />
        ) : (
          <Badge badgeContent={badge} />
        )}
      </Button>
    </ListItem>
  );
};

export default SidebarMenuItem;
