import { FC, useState, createContext, ReactNode, useEffect } from 'react';
import { Advertiser } from 'src/models/advertiser';
import Feature from 'src/models/feature';
import { enrichedDefaultAccounts } from 'src/constants/accountConfig';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useRelevantSearchParams } from 'src/hooks/useRelevantSearchParams';


type ImpactContext = {
  selectedAdvertiser: Advertiser;
  isImpactInfinityReadOnly: boolean;
  setIsImpactInfinityReadOnly: (isImpactInfinityReadOnly: boolean) => void;
  showImpactInfinity: boolean;
  setShowImpactInfinity: (showImpactInfinity: boolean) => void;
  advertiserList: Advertiser[];
  setSelectedAdvertiser: (advertiser: Advertiser) => void;
  setAdvertiserList: (advertiserList: Advertiser[]) => void;
  featureList: Feature[];
  setFeatureList: (featureList: Feature[]) => void;
  selectedFeature: Feature;
  setSelectedFeature: (feature: Feature) => void;
  dateRange: Date;
  setDateRange: (dateRange: Date) => void;
  customeDataRange: string;
  setCustomeDataRange: (customeDataRange: string) => void;
  isSuperAdmin: string;
  setIsSuperAdmin: (isSuperAdmin: string) => void;
  viewMode: string;
  setViewMode: (viewMode: string) => void;
  superAccess: string;
  setSuperAccess: (superAccess: string) => void;
  sidebarMode: string;
  setSidebarMode: (sidebarMode: string) => void;
  pageLimit: number;
  setPageLimit: (limit: number) => void;
  toggleDataType: string;
  settoggleDataType: (toggleDataType: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImpactContext = createContext<ImpactContext>({} as ImpactContext);

export const ImpactProvider: FC<{ children: ReactNode}> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

const data = localStorage.getItem('selectedAdvertiser');
let accountId: number = 0;
if(data){
  accountId =  JSON.parse(data)?.account?.accountId;
}
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser>(null);
const defaultDataType = enrichedDefaultAccounts.hasOwnProperty(accountId) ? 'enriched' : 'observed';
  const [toggleDataType, settoggleDataType] = useState<string>(defaultDataType);
  const [advertiserList, setAdvertiserList] = useState<Advertiser[]>([]);
  const [featureList, setFeatureList] = useState<Feature[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<Feature>(null);
  const [dateRange, setDateRange] = useState<Date>(new Date());
  const [customeDataRange, setCustomeDataRange] = useState<string>('');
  const [isImpactInfinityReadOnly, setIsImpactInfinityReadOnly] = useState<boolean>(true);
  const [showImpactInfinity, setShowImpactInfinity] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<string>(
    localStorage.getItem('isSuperAdmin') !== null
      ? localStorage.getItem('isSuperAdmin')
      : 'false'
  );
  const [viewMode, setViewMode] = useState<string>(
    localStorage.getItem('viewMode') !== null
      ? localStorage.getItem('viewMode')
      : 'user'
  );
  const [superAccess, setSuperAccess] = useState<string>(
    localStorage.getItem('superAccess') !== null
      ? localStorage.getItem('superAccess')
      : ''
  );
  const [sidebarMode, setSidebarMode] = useState<string>(
    localStorage.getItem('SidebarMode') !== null
      ? localStorage.getItem('SidebarMode')
      : 'Expanded'
  );
  const [pageLimit, setPageLimit] = useState<number>(
    parseInt(localStorage.getItem('pageLimit') || '10')
  );
  useEffect(() => {
    if(data) {
      const defaultDataType = enrichedDefaultAccounts.hasOwnProperty(accountId) ? 'enriched' : 'observed';
      settoggleDataType(defaultDataType);
    }
  }, [data]);
  useEffect(() => {
    localStorage.setItem('pageLimit', pageLimit.toString());
  }, [pageLimit]);
 // Effect hook to update toggleDataType based on URL change
 useEffect(() => {
  if(!enrichedDefaultAccounts.hasOwnProperty(accountId)){
    const urlDataType = searchParams.get('dataType');
    if (urlDataType) {
      // If dataType is present in the URL, set the toggleDataType state
      settoggleDataType(urlDataType);
    }
  }
}, []); 
useEffect(() => {
  // Check if the current path is either insights/media or insights/site
  if (/\/insights\/(media|site)/.test(location.pathname)) {
    const currentDataType = searchParams.get('dataType');

    if (toggleDataType !== currentDataType) {
      // Set the dataType parameter last by creating a new URLSearchParams object
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('dataType'); // Remove the parameter to re-add it last
      newSearchParams.set('dataType', toggleDataType);

      // Construct the new search string
      const newSearchString = newSearchParams.toString();

      setSearchParams(newSearchString, { replace: true });
    }
  }
}, [toggleDataType, location.pathname]); // Only re-run effect if toggleDataType or pathname changes

  return (
    <ImpactContext.Provider
      value={{
        selectedAdvertiser,
        advertiserList,
        isImpactInfinityReadOnly,
        showImpactInfinity,
        setIsImpactInfinityReadOnly,
        setShowImpactInfinity,
        setSelectedAdvertiser,
        setAdvertiserList,
        featureList,
        setFeatureList,
        selectedFeature,
        setSelectedFeature,
        dateRange,
        setDateRange,
        customeDataRange,
        setCustomeDataRange,
        isSuperAdmin,
        setIsSuperAdmin,
        viewMode,
        setViewMode,
        superAccess,
        setSuperAccess,
        sidebarMode,
        setSidebarMode,
        pageLimit,
        setPageLimit,
        toggleDataType,
        settoggleDataType
      }}
    >
      {children}
    </ImpactContext.Provider>
  );
};
