import { Fragment, useRef, useState, useEffect, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import axios from 'src/utils/axios';
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Button,
  Divider,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  Avatar,
  styled,
  useTheme
} from '@mui/material';
import { DotLegend } from 'src/content/materialUI/customStyle';
import Text from 'src/components/Text';
import Scrollbar from 'src/components/Scrollbar';
import CampaignIcon from '@mui/icons-material/Campaign';
import MarkChatReadTwoToneIcon from '@mui/icons-material/MarkChatReadTwoTone';
import { useTranslation } from 'react-i18next';
import { Advertiser } from 'src/models/advertiser';
import { ADVERTISER_LIST_URL } from 'src/constants/urls';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { getFeaturesList } from 'src/api/impactApi';
import useAuth from 'src/hooks/useAuth';

const ListWrapper = styled(List)(
  () => `
  .MuiListItem-root:last-of-type + .MuiDivider-root {
      display: none;
  }
`
);

function AdvertiserSwitcher() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setShowImpactInfinity, setIsImpactInfinityReadOnly } =
    useContext(ImpactContext);
  const [advertiserList, setAdvertiserList] = useState<Advertiser[]>([]);
  const [selectedAdvertiser, setSelectedAdvertiser] =
    useState<Advertiser>(null);
  const theme = useTheme();
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { t }: { t: any } = useTranslation();

  //  Fetch advertisers using useQuery (like PageHeader)
  const { data: advertiserListData } = useQuery({
    queryKey: [
      'feature_selection_advertiser_list',
      searchParams.get('accountId')
    ],
    queryFn: () =>
      axios
        .get(`${ADVERTISER_LIST_URL}${searchParams.get('accountId')}`)
        .then((res) => res.data.list)
  });

  //  Fetch features using useQuery (like PageHeader)
  const { data: featureListData } = useQuery({
    queryKey: ['feature_selection_features', selectedAdvertiser?.advertiserId],
    queryFn: () =>
      getFeaturesList(
        selectedAdvertiser?.advertiserId,
        Number(searchParams.get('accountId'))
      ),
    enabled: !!selectedAdvertiser // Only fetch when an advertiser is selected
  });

  // Handle advertiser selection
  const changeAdvertiser = (advertiser: Advertiser) => {
    setSelectedAdvertiser(advertiser);
    localStorage.setItem('selectedAdvertiser', JSON.stringify(advertiser));

    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      advertiserId: advertiser.advertiserId.toString()
    });

    setOpen(false);
  };

  // Update advertiser list when fetched
  useEffect(() => {
    if (advertiserListData) {
      setAdvertiserList(advertiserListData);
      const selectedAdv =
        advertiserListData.find(
          (a) => a.advertiserId === Number(searchParams.get('advertiserId'))
        ) || advertiserListData[0]; // Default to the first advertiser
      setSelectedAdvertiser(selectedAdv);
    }
  }, [advertiserListData]);

  //  Update features when advertiser changes
  useEffect(() => {
    if (featureListData) {
      //  Check if advertiser has feature 100 or 101
      const hasImpactInfinityFeature =
        featureListData?.data?.list?.some(
          (feature) => feature.featureId === 100 || feature.featureId === 101
        ) && user?.permissions?.includes(46);
      setShowImpactInfinity(hasImpactInfinityFeature);

      if (hasImpactInfinityFeature) {
        // Ensure 'infinity' param exists if missing
        if (!searchParams.get('infinity')) {
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            infinity: 'false'
          });
        }
      } else {
        //  Remove 'infinity' param if feature 101 is missing
        const updatedParams = { ...Object.fromEntries(searchParams.entries()) };
        delete updatedParams.infinity;
        setSearchParams(updatedParams);
      }
    }
  }, [featureListData]);
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={'Change Advertiser'} sx={{ ml: 1 }}>
        <Button
          ref={ref}
          onClick={handleOpen}
          size="small"
          variant="text"
          startIcon={<CampaignIcon fontSize="small" />}
        >
          {selectedAdvertiser !== null
            ? selectedAdvertiser.name
            : ' Select Advertiser'}
        </Button>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={360} maxWidth={360}>
          <Box
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background: `${alpha(theme.colors.alpha.black[100], 0.07)}`
            }}
          >
            <Button
              size="small"
              color="primary"
              startIcon={<MarkChatReadTwoToneIcon />}
              sx={{
                fontSize: theme.typography.pxToRem(12)
              }}
            >
              Select Advertise to change data accordingly
            </Button>
          </Box>

          <Divider />
          <Box
            sx={{
              height: 200
            }}
          >
            <Scrollbar>
              <ListWrapper disablePadding>
                {advertiserList.length &&
                  advertiserList?.map((item, index) => (
                    <Fragment key={index}>
                      <ListItem
                        sx={{
                          py: 1.5,
                          '&:hover': {
                            background: `${theme.colors.alpha.black[5]}`
                          }
                        }}
                        secondaryAction={
                          <Button
                            onClick={() => {
                              changeAdvertiser(item);
                            }}
                            size="small"
                            variant="text"
                            color="secondary"
                            sx={{
                              alignSelf: 'center',
                              padding: `${theme.spacing(0.5, 1.5)}`,
                              backgroundColor: `${theme.colors.secondary.lighter}`,
                              textTransform: 'uppercase',
                              fontSize: `${theme.typography.pxToRem(11)}`,
                              '&:hover': {
                                backgroundColor: `${theme.colors.secondary.main}`,
                                color: `${theme.palette.getContrastText(
                                  theme.colors.secondary.main
                                )}`
                              }
                            }}
                          >
                            {t('Select')}
                          </Button>
                        }
                      >
                        <ListItemAvatar
                          sx={{
                            minWidth: 38,
                            mr: 1
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 38,
                              height: 38
                            }}
                            alt={item.name}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            flexGrow: 0,
                            maxWidth: '50%',
                            flexBasis: '50%'
                          }}
                          disableTypography
                          primary={
                            <Typography
                              sx={{
                                pb: 0.6
                              }}
                              color="text.primary"
                              variant="h5"
                            >
                              {item.name}
                            </Typography>
                          }
                          secondary={
                            item?.advertiserId ===
                              selectedAdvertiser?.advertiserId && (
                              <Box display="flex" alignItems="flex-start">
                                <DotLegend
                                  margin={theme.spacing(0.5)}
                                  width="10px"
                                  height="10px"
                                  style={{
                                    background: `${theme.colors.success.main}`
                                  }}
                                />
                                <Typography
                                  sx={{
                                    fontSize: `${theme.typography.pxToRem(11)}`,
                                    lineHeight: 1
                                  }}
                                  variant="body1"
                                >
                                  <Text color="success">{'Active'}</Text>
                                </Typography>
                              </Box>
                            )
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
              </ListWrapper>
            </Scrollbar>
          </Box>
          <Divider />
        </Box>
      </Popover>
    </>
  );
}

export default AdvertiserSwitcher;
