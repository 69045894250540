import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

const Dashboard = Loader(
  lazy(() => import('src/content/dashboards/Dashboard'))
);
const ActivityBreakdown = Loader(
  lazy(() => import('src/content/dashboards/ActivityBreakdown'))
);
const Markets = Loader(lazy(() => import('src/content/dashboards/Markets')));
const TravelProfile = Loader(
  lazy(() => import('src/content/dashboards/TravelProfile'))
);
const OriginBreakdown = Loader(
  lazy(() => import('src/content/dashboards/OriginBreakdown'))
);
const TripPlanning = Loader(
  lazy(() => import('src/content/dashboards/TripPlanning'))
);
const OverlapAndUnique = Loader(
  lazy(() => import('src/content/dashboards/OverlayAndUnique'))
);
const MediaReferral = Loader(
  lazy(() => import('src/content/dashboards/MediaReferral'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);

const mediaRoutes = [
  {
    path: '',
    element: <Dashboard />
  },

  {
    path: 'dashboard',
    element: <Dashboard />
  },
  {
    path: 'activity-breakdown',
    element: <ActivityBreakdown />
  },
  {
    path: 'media-referrals',
    element: <MediaReferral />
  },
  {
    path: 'orign-breakdown',
    element: <OriginBreakdown />
  },
  {
    path: 'traveler-profile',
    element: <TravelProfile />
  },
  {
    path: 'trip-planning',
    element: <TripPlanning />
  },
  {
    path: 'markets',
    element: <Markets />
  },
  {
    path: 'overlap-uniques',
    element: <OverlapAndUnique />
  },
  {
    path: 'media-referrals/coming-soon',
    element: <StatusComingSoon />
  },
  {
    path: 'traveler-profile/coming-soon',
    element: <StatusComingSoon />
  }
];

export default mediaRoutes;
