import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { Navigate } from 'react-router-dom';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Blocks

const Overview = Loader(lazy(() => import('src/content/site/Overview')));
const Markets = Loader(lazy(() => import('src/content/site/Markets')));
const TravelProfile = Loader(
  lazy(() => import('src/content/site/TravelProfile'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const OriginBreakdown = Loader(
  lazy(() => import('src/content/site/OriginBreakdown'))
);
const TripPlanning = Loader(
  lazy(() => import('src/content/site/TripPlanning'))
);

const sitwRoutes = [
  {
    path: '',
    element: <Overview />
  },

  {
    path: 'overview',
    element: <Overview />
  },
  {
    path: 'orign-breakdown',
    element: <OriginBreakdown />
  },
  {
    path: 'site-visitor-profile',
    element: <TravelProfile />
  },
  {
    path: 'trip-planning',
    element: <TripPlanning />
  },
  {
    path: 'markets',
    element: <Markets />
  },
  {
    path: 'coming-soon',
    element: <StatusComingSoon />
  }
];

export default sitwRoutes;
