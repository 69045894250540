// import { Suspense, lazy } from 'react';
// import SuspenseLoader from 'src/components/SuspenseLoader';
// const Loader = (Component) => (props) =>
//   (
//     <Suspense fallback={<SuspenseLoader />}>
//       <Component {...props} />
//     </Suspense>
//   );

// const IdentitiesSummary = Loader(
//   lazy(() => import('src/content/privateConsortium/identitiesSummary'))
// );

// const privateConsortiumRoute = [
//   {
//     path: '',
//     element: <IdentitiesSummary />
//   },

//   {
//     path: 'identities-summary',
//     element: <IdentitiesSummary />
//   }
// ];

// export default privateConsortiumRoute;
import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
  const IdentitiesSummary = Loader(
    lazy(() => import('src/content/privateConsortium/identitiesSummary'))
  );
  const ImpressionLogs = Loader(
    lazy(() => import('src/content/privateConsortium/ImpressionLogs'))
  );
  const TravelBehaviorScores = Loader(
    lazy(() => import('src/content/privateConsortium/travelBehaviorScores'))
  );
  const StatusComingSoon = Loader(
    lazy(() => import('src/content/pages/Status/ComingSoon'))
  );
let privateConsortiumRoute = [];

privateConsortiumRoute = [
  {
    path: '',
    element: <Navigate to="identities-summary" replace />
  },
  {
    path: 'identities-summary',
    element: <IdentitiesSummary />
  },
  {
    path: 'impression-logs',
    element: <ImpressionLogs />
  },
  {
    path: 'travel-behavior-scores',
    element: <TravelBehaviorScores />
  },
  {
    path: 'coming-soon',
    element: <StatusComingSoon />
  }
];
//}

export default privateConsortiumRoute;
