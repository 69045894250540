import {
  alpha,
  Box,
  Menu,
  MenuItem,
  styled,
  Switch,
  Tooltip
} from '@mui/material';
import AdvertiserSwitcher from './AdvertiserSwitcher';
import { useSearchParams } from 'react-router-dom';
import DateSelection from './Dateselection';
import { useContext, useEffect, useState } from 'react';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { makeStyles } from '@mui/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TabIcon from '@mui/icons-material/Tab';
import WindowIcon from '@mui/icons-material/Window';
const useStyles = makeStyles((theme) => ({
  switch: {
    '& .Mui-checked + .MuiSwitch-track': {
      opacity: '1 !important'
    },
    '& .Mui-checked.Mui-disabled': {
      color: '#CC0066 !important'
    }
  }
}));
const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(0.1),
    minWidth: 240,
    border: '1px solid #d3d3d3',
    boxShadow: '0px 1px 1px 0px rgba(207, 207, 207, 0.1)',
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
    '& .MuiMenu-list': {
      padding: '8px 0'
    },
    '& .MuiMenuItem-root': {
      color:
        theme.palette.mode === 'dark'
          ? theme.palette.text.secondary
          : theme.palette.text.primary,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color:
          theme.palette.mode === 'dark'
            ? theme.palette.text.secondary
            : theme.palette.text.primary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

function HeaderButtons() {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isImpactInfinityReadOnly, showImpactInfinity } =
    useContext(ImpactContext);
  const isImpactInfinity = searchParams.get('infinity') === 'true';

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  const handleToggleClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  const handleMenuItemClick = (option: string) => {
    handleMenuClose();
    switch (option) {
      case 'newTab':
        openNewTab();
        break;
      case 'sameTab':
        toggleInfinity();
        break;
      case 'newWindow':
        openNewWindow();
        break;
      default:
        break;
    }
  };
  const toggleInfinity = () => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      infinity: isImpactInfinity ? 'false' : 'true'
    });
  };

  const openNewTab = () => {
    const newInfinityState = !isImpactInfinity;
    const url = new URL(window.location.href);
    url.searchParams.set('infinity', newInfinityState ? 'true' : 'false');
    window.open(url.toString(), '_blank');
  };

  const openNewWindow = () => {
    const newInfinityState = !isImpactInfinity;
    const url = new URL(window.location.href);
    url.searchParams.set('infinity', newInfinityState ? 'true' : 'false');
    const windowFeatures = 'width=1400,height=800,scrollbars=yes,resizable=yes';
    window.open(url.toString(), '_blank', windowFeatures);
  };
  return (
    <Box sx={{}}>
      {showImpactInfinity && (
        <Tooltip
          arrow
          title={
            'Infinity is the new version of Impact, that uses 100% 3P cookie-free data sources to deliver the Impact metrics. This offers a measurement solution as the cookie deprecation occurs in the marketplace.'
          }
          sx={{ ml: 1 }}
        >
          <span>
            <Switch
              color="primary"
              disabled={isImpactInfinityReadOnly}
              checked={isImpactInfinity}
              onChange={handleToggleClick} // Open the menu on toggle
              className={classes.switch}
            />
            <span style={{ fontWeight: '900', marginRight: '0.8rem' }}>
              Impact Infinity
            </span>
          </span>
        </Tooltip>
      )}

      {/* Menu for Toggle Options */}
      <StyledMenu
        anchorEl={anchorElMenu}
        open={Boolean(anchorElMenu)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('newTab')} disableRipple>
          <OpenInNewIcon />
          Open in New Tab
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('sameTab')} disableRipple>
          <TabIcon />
          Open in Same Tab
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick('newWindow')}
          disableRipple
        >
          <WindowIcon />
          Open in New Window
        </MenuItem>
      </StyledMenu>

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 &&
        window.location.href.indexOf('private-consortium') === -1 && (
          <DateSelection />
        )}
      {/* <LanguageSwitcher /> */}

      {searchParams.get('advertiserId') !== null &&
        window.location.href.indexOf('insights') > -1 && <AdvertiserSwitcher />}
    </Box>
  );
}

export default HeaderButtons;
