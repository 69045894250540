import { Box, Divider, List, styled } from '@mui/material';
import { useLocation, matchPath, useSearchParams, useNavigate } from 'react-router-dom';
import SidebarMenuItem from './item';
import { MenuItem, MenuItems } from './items';
import { v4 as uuidv4 } from 'uuid';
import { useContext, useEffect, useState } from 'react';
import { getFeaturesList } from 'src/api/impactApi';
import { ImpactContext } from 'src/contexts/ImpactContext';
import { checkUrl } from 'src/utils/checkUrl';
import { Home, Settings, Web } from '@mui/icons-material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AppsIcon from '@mui/icons-material/Apps';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import CampaignIcon from '@mui/icons-material/Campaign';
import useAuth from 'src/hooks/useAuth';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

const MenuWrapper = styled(Box)(
  ({ theme }) => `

  &:last-of-type + .MuiDivider-root {
      height: 0;
  }

  .MuiList-root {
    padding: ${theme.spacing(1, 0)};

    .MuiDivider-root {
      background: ${theme.sidebar.menuItemIconColor};
      margin-left: ${theme.spacing(3)};
      margin-right: ${theme.spacing(3)};
    }

    & > .MuiList-root {
      padding: 0;
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 1px 0;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: ${theme.spacing(6)};
          height: ${theme.spacing(6)};
          transition: ${theme.transitions.create(['all'])};
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
          }

          .MuiBadge-root {
            position: absolute;
            right: 10px;
            top: 11px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(10)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
}): Array<JSX.Element> => {
  const key = uuidv4();

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const { user } = useAuth();
 const navigate = useNavigate();
  const { isSuperAdmin, setIsImpactInfinityReadOnly, setShowImpactInfinity } =
    useContext(ImpactContext);
  const location = useLocation();
  const viewMode = localStorage.getItem('viewMode');
  const [searchParams, setSearchParams] = useSearchParams();
  const isImpactInfinity = searchParams.get('infinity') === 'true';
  const { search } = useLocation();
  const [features, setFeatures] = useState<any[]>([]);
  const hasMediaFeature = features?.some((feature) => feature?.featureId === 3);
  const hasSiteFeature = features?.some((feature) => feature?.featureId === 6);
  const hasPrivateConsortiumFeature = features?.some(
    (feature) => feature?.featureId === 12
  );
  const checFeaturekUrl = () => {
    if (
      window.location.href.indexOf('advertiserId') > -1 ||
      window.location.href.indexOf('features') > -1
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!features || features.length === 0) return; // ✅ Ensure features are fully loaded before execution

    // Hide toggle if neither 100 nor 101 is present
    const hasInfinityFeatures = features.some(
      (feature) => feature.featureId === 100 || feature.featureId === 101
    );

    setShowImpactInfinity(
      hasInfinityFeatures && user?.permissions?.includes(46)
    );

    const updatedParams = new URLSearchParams(searchParams); // ✅ Clone current search params

    // Checking for impact infinity feature options available for an advertiser
    if (features.some((feature) => feature.featureId === 101)) {
      setIsImpactInfinityReadOnly(false);

      // ✅ Ensure we only update searchParams if necessary
      if (!updatedParams.get('infinity') && user?.permissions?.includes(46)) {
        updatedParams.set('infinity', 'false');
      }
    } else {
      setIsImpactInfinityReadOnly(true);

      // ✅ Remove 'infinity' from URL if feature 101 is missing
      if (updatedParams.has('infinity')) {
        updatedParams.delete('infinity');
      }
    }

    // ✅ Check if only infinity is selected
    if (
      features.some((feature) => feature.featureId === 100) &&
      user?.permissions?.includes(46)
    ) {
      if (updatedParams.get('infinity') !== 'true') {
        updatedParams.set('infinity', 'true');
      }
    }

    // ✅ Ensure we only modify params when necessary
    setSearchParams(updatedParams);
  }, [features]);

  const generalItems: MenuItems[] = [
    {
      heading: 'General',
      items: [
        // {
        //   name: 'Home',
        //   link: '/home',
        //   badge: '',
        //   badgeTooltip: 'Return to home page',
        //   icon: Home
        // },

        { name: 'Features Selection', link: '/features', icon: AppsIcon }
      ]
    }
  ];
  const accountItems: MenuItems[] = [
    {
      heading: 'Company Account',
      items: [
        {
          name: 'User Information',
          link: '/company-account/personal-account',
          icon: ManageAccountsIcon
        },
        {
          name: 'Users',
          link: '/company-account/users',
          icon: PeopleIcon
        },
        {
          name: 'Roles',
          icon: PermDataSettingIcon,
          link: '/company-account/roles'
        },
        ...(viewMode === 'admin'
          ? [
              {
                name: 'Advertisers',
                link: '/company-account/advertisers',
                icon: CampaignIcon
              }
            ]
          : [])
      ]
    }
  ];
  const mediaItems: MenuItems[] = checkUrl('insights')
    ? [
        {
          heading: 'Impact',
          items: [
            ...(hasMediaFeature
              ? [
                  {
                    name: 'Media Impact',
                    icon: AnalyticsIcon,
                    link: '/insights/media',
                    items: [
                      {
                        name: 'Dashboard',
                        link: '/insights/media/dashboard',
                        badge: '',
                        badgeTooltip: 'Dashboard page'
                      },
                      {
                        name: 'Activity Breakdown',
                        link: '/insights/media/activity-breakdown'
                      },
                      {
                        name: 'Media Referrals',
                        link:
                          searchParams.get('infinity') === 'true'
                            ? '/insights/media/coming-soon'
                            : '/insights/media/media-referrals'
                      },
                      {
                        name: 'Origin Breakdown',
                        link: '/insights/media/orign-breakdown'
                      },
                      ...(searchParams.get('infinity') !== 'true'
                        ? [
                            {
                              name: 'Traveler Profile',
                              link: '/insights/media/traveler-profile'
                            }
                          ]
                        : []),
                      {
                        name: 'Trip Planning',
                        link: '/insights/media/trip-planning'
                      },
                      {
                        name: 'Markets',
                        link: '/insights/media/markets'
                      },
                      {
                        name: 'Overlap & Uniques',
                        link: '/insights/media/overlap-uniques'
                      }
                    ]
                  }
                ]
              : []),
            ...(hasSiteFeature && searchParams.get('infinity') !== 'true'
              ? [
                  {
                    name: 'Site Impact',
                    icon: Web,
                    link: '/site/overview',
                    items: [
                      { name: 'Overview', link: '/insights/site/overview' },
                      {
                        name: 'Origin Breakdown',
                        link: '/insights/site/orign-breakdown'
                      },
                      {
                        name: 'Site Visitor Profile',
                        link: '/insights/site/site-visitor-profile'
                      },
                      {
                        name: 'Trip Planning',
                        link: '/insights/site/trip-planning'
                      },
                      {
                        name: 'Markets',
                        link: '/insights/site/markets'
                      }
                    ]
                  }
                ]
              : []),
            ...(hasPrivateConsortiumFeature
              ? [
                  {
                    name: 'Private Consortium',
                    icon: GroupWorkIcon,
                    link: 'private-consortium/identities-summary',
                    items: [
                      ...(process.env.REACT_APP_ENV === 'prod'
                        ? [
                            {
                              name: 'Travel Behavior Dashboard',
                              link: '/insights/private-consortium/travel-behavior-scores'
                            }
                          ]
                        : [
                            {
                              name: 'Identities Summary',
                              link: '/insights/private-consortium/identities-summary'
                            },
                            {
                              name: 'Impression Logs',
                              link: '/insights/private-consortium/impression-logs'
                            },
                            {
                              name: 'Travel Behavior Dashboard',
                              link: '/insights/private-consortium/travel-behavior-scores'
                            }
                          ])
                    ]
                  }
                ]
              : []),
            {
              name: 'Impact Setup',
              icon: Settings,
              link: '/insights/setup',

              items: [
                {
                  name: 'Attribution Groups',
                  link: '/insights/setup/attribution-groups'
                },
                { name: 'Pixels', link: '/insights/setup/pixels' },
                ...(user?.permissions?.includes(19)
                  ? [{ name: 'Logs', link: '/insights/setup/logs' }]
                  : [])
              ]
            }
          ]
        }
      ]
    : [];

  let adminItems: MenuItems[] = [];
  adminItems = [
    {
      heading: 'Internal Admin',
      items: [
        {
          name: 'Accounts',
          link: '/admin/accounts',
          icon: ManageAccountsIcon
        }
      ]
    }
  ];
  const getFeatures = async (): Promise<void> => {
    await getFeaturesList(
      Number(searchParams.get('advertiserId')),
      Number(searchParams.get('accountId'))
    )
      .then((res) => {
        if (res.status === 200) {
          setFeatures(res?.data?.list);
        }
      })
      .catch((err) => {
        console.log("Can't fetch Features", err);
      });
  };
  useEffect(() => {
    if (Number(searchParams.get('advertiserId'))) {
      getFeatures();
    }
  }, [search, searchParams, isSuperAdmin]);
  useEffect(() => {
    const currentPath = location.pathname;
    if (searchParams.get('infinity') === 'true') {
      if (currentPath.includes('/insights/media/media-referrals')) {
        navigate(
          '/insights/media/media-referrals/coming-soon' + location.search
        );
      } else if (currentPath.includes('/insights/media/traveler-profile')) {
        navigate(
          '/insights/media/traveler-profile/coming-soon' + location.search
        );
      } else if (currentPath.includes('/insights/site')) {
        navigate('/insights/site/coming-soon' + location.search);
      } 
    } else {
      if (currentPath.includes('/insights/media/media-referrals/coming-soon')) {
        navigate('/insights/media/media-referrals' + location.search);
      } else if (
        currentPath.includes('/insights/media/traveler-profile/coming-soon')
      ) {
        navigate('/insights/media/traveler-profile' + location.search);
      } else if (currentPath.includes('/insights/site/coming-soon')) {
        navigate('/insights/site/overview' + location.search);
      } 
    }
  }, [searchParams.get('infinity')]);

  return (
    <>
      <>
        {generalItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        ))}

        {searchParams.get('advertiserId') !== null && (
          <>
            {mediaItems.map((section) => (
              <MenuWrapper key={section.heading}>
                <List component="div">
                  {renderSidebarMenuItems({
                    items: section.items,
                    path: location.pathname
                  })}
                </List>
              </MenuWrapper>
            ))}
          </>
        )}

        {accountItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        ))}
        <Divider />
      </>
    </>
  );
}

export default SidebarMenu;
